.footer {
  background: #fff;
}

.footerIcons,
.insta {
  margin: 0 45px 0 45px;
  margin-top: 35px;
}

.xcorp,
.insta,
.youtube {
  height: 33px;
}

.footerhr {
  height: 15px;
  margin-top: 45px;
}
.footerNav {
  margin: 30px 0;
}
.footerNav ul {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  list-style: none;
  font-size: 26px;
  margin-left: -45px;
}

.footerNav ul li a {
  text-decoration: none;
  color: #000;
}
.companydetail {
  font-size: 21px;
  font-weight: 400;
  line-height: 184%; 
}
