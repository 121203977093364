.contantBanner {
  color: #0000008f;
  font-size: 38.656px;
  font-style: italic;
  font-weight: 700;
  line-height: 43.488px; 
  letter-spacing: 1.208px;
  text-transform: uppercase;
}
.btn {
    display: inline-flex;
    padding: 8px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: rgba(0, 0, 0, 56%);
    color: #fcfcfc;
    font-size: 17px;
    border: none;
    margin-top: 81px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* margin-top: 321px; */
  text-transform: uppercase;
}
.banner {
    position: relative;
    overflow: hidden;
  }
  
  .banner img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  
  .contantBanner {
    position: absolute;
    margin-top: 98px;
    top: 40%;
    left: 55%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #00000066; 
  }
  
  .contantBanner p {
    font-size: 61px;
    color: #555;
    margin-right: 40px;
    margin-bottom: 15px;
  }
  

  
  .btn:hover {
    background-color: #555; 
  }
  

.banner {
  position: relative;
}

.desktop-image,
.mobile-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .mobile-image {
    display: none; 
  }
}

@media (max-width: 767px) {
  .desktop-image {
    display: none; 
  }
}

@media  (max-width: 767px){
  .contantBanner p {
    font-size: 38.656px;
  }
}
@media (max-width: 767px){
  .contantBanner {
    position: absolute;
    margin-top: 98px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #00000066; 
  }
}

