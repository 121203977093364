h1 {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Tenor Sans;
  margin: 4em 0 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 4px;
  text-transform: uppercase;
}
.lable{
  margin: 23px;
}
.container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.boxs {
  width: 30%;
  padding: 25px;
  margin-bottom: 16px;
}

.imagesfit {
  object-fit: contain;
  height: 300px;
  width: 100%;
}
.pricestyle {
    font-family: Tenor Sans;
  color: red;
}

.exploreMore {
  display: inline-flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color:  #000;
text-align: center;
font-family: Tenor Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
}
button{
    color: #000;
}
